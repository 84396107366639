/* -------------------------------------
 CLEAR FORM
 -------------------------------------
 */

export default (form) => {
    if (form) {
        form.find('input[type=text], input[type=email], input[type=password], textarea').val('');
        form.find('select').select2('val', '');
        form.trigger('submit');
    }
};
