import $ from 'jquery';
import AjaxSpinner from './AjaxSpinner';
import BlockHeights from './BlockHeights';
/* eslint-disable camelcase,func-names,no-plusplus */

/* -------------------------------------
 AJAX NAVIGATION
 eg. Codewords, Campaign
 -------------------------------------
 */
export default () => {
    // Get more items via ajax
    if ($('.previous-issues-container').length > 0) {
        // Fetch more results for each tab
        $('.previous-issues-container .show-more').on('click', function () {
            const $this = $(this);
            const ajax_container = $this.closest('.previous-issues-container').find('.js-ajax-container');
            const url = $this.attr('href');

            if (ajax_container.length > 0) {
                ajax_container.fadeIn();
                // Show spinner
                AjaxSpinner(ajax_container, 'show');
                // Get some more results
                $.get(url, (data) => {
                    ajax_container.html(data);
                    // On tablet, we are hding some results. Show them if ajax has run
                    ajax_container.parent().addClass('js-ajax-opened');
                    // Run the height adjuster
                    BlockHeights($this.parent().find('.block-news-item'));
                });
            }
            $(this).hide();
            return false;
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
