import $ from 'jquery';

/* -------------------------------------
RATING AND FEEDBACK
 -------------------------------------
 */

/*eslint-disable */
const Init = () => {
    $('.rating ul.optionset:not([disabled]) li').on({
        'mouseenter': function () {
            $(this).prevAll('li:not(.rating-hovered)').andSelf().addClass('rating-hovered');
        },
        'mouseleave': function () {
            $(this).prevAll('li').andSelf().removeClass('rating-hovered');
        },
    });

    $('.rating ul.optionset:not([disabled]) input').on({
        'change': function () {
            var li = $(this).parent();
            li.nextAll('li').removeClass('rating-selected');
            li.prevAll('li').andSelf().addClass('rating-selected');
        },
        'focus': function () {
            $('.rating ul.optionset:not([disabled]) li').removeClass('focussed');
            $(this).parent().addClass('focussed');
        },
        'blur': function () {
            $('.rating ul.optionset:not([disabled]) li').removeClass('focussed');
        },
    });
};

const Update = () => {
    $('.rating ul.optionset input:checked').each(function () {
        var li = $(this).parent();
        li.prevAll('li').andSelf().addClass('rating-selected');

        li.off('mouseenter mouseleave click');
    });
};

export default  {
    Init,
    Update,
}
/* eslint-enable */
