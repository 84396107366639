import $ from 'jquery';
/* -------------------------------------
 ACCORDION
 -------------------------------------
 */
/* eslint-disable camelcase,func-names,no-plusplus */
export default () => {
    const acc = document.getElementsByClassName('accordion');
    let i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
            this.classList.toggle('active');
            const panel = $(this).find('.panel');
            const icon = $(this).find('.accordion-icon-plus');
            if (panel.css('opacity') === '1') {
                panel.css('opacity', 0);
                panel.css('height', 0);
                panel.css('paddingTop', 0);
                icon.css('opacity', 1);
            } else {
                panel.css('opacity', 1);
                panel.css('height', 'auto');
                panel.css('paddingTop', '18px');
                icon.css('opacity', 0);
            }
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
