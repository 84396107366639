import $ from 'jquery';
import SafeForCss from '../utils/SafeForCss';

/* eslint-disable camelcase,func-names,no-plusplus */
/* -------------------------------------
 TERTIARY MENU
 -------------------------------------
 */
export default () => {
    const nav_tertiary = $('.nav-tertiary');
    const header_twos = $('#content h2:not(.js-ignore), .js-content h2:not(.js-ignore)');
    const base_href = window.location.pathname;
    let count = 0;
    const showAll = nav_tertiary.data('show-all');

    // Check if we need a ul
    if ((header_twos.length > 0) && (nav_tertiary.length > 0)) {
        // Check for only one heading, at the top - no jump link required for this
        if ((header_twos.length === 1) && !showAll) {
            return;
        }
        let $menu;
        if (nav_tertiary.find('ul').length === 0) {
            $menu = $('<ul/>');
        }
        if (!$menu) {
            return;
        }
        // Loop through header twos
        header_twos.each(function () {
            const heading = $(this);
            const heading_text = heading.text();
            const heading_id = `jumpto-${SafeForCss(heading_text)}`;

            // Ignore additional h2's inside blocks
            if (heading.closest('.block-referral').length === 0) {
                count++;
                heading.attr('id', heading_id);
                $menu.append(`<li><a href="${base_href}#${heading_id}"><span class="icon icon-down"></span>${heading_text}</a></li>`);
            }
        });
        if (count > 0) {
            nav_tertiary.append('<h2 class="nav-tertiary-heading">On this page:</h2>').append($menu);
        }
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
