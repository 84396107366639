import $ from 'jquery';
import '../plugins/jquery.fancybox.v2.1.5';

/*eslint-disable */
/*
 -------------------------------------
 AJAX FORMS

 On success, whole form gets replaced by returned markup.
 On error, alert message is displayed.

 Such forms should be only used in conditions where it's obvious they were
 loaded using JavaScript, e.g. in modals.

 Usage:
 <form class="js-form-ajax">
 <input ... >
 <input ... >
 </form>

 -------------------------------------
 */

$.fn.mbie_ajax_forms = function () {

    return this.each(function (i, el) {

        // Variables
        let form = $(el),
            form_action = form.attr('action'),
            form_method = form.attr('method') || 'get';

        // ensure form action is absolute as we don't use base tag
        if (form_action.substring(0, 1) !== '/') {
            form_action = '/' + form_action;
        }

        form.submit(function (event) {

            $.ajax({
                url: form_action,
                type: form_method,
                data: form.serialize()
            })
                .done(function (html) {
                    form.replaceWith(html);
                    $.fancybox.update();
                })
                .fail(function () {
                    alert("Form submission failed, please try again.");
                });

            event.preventDefault();
        });
    });
};
/* eslint-enable */

export default () => {
    // AJAX forms
    $('.js-form-ajax').mbie_ajax_forms();
};
