import $ from 'jquery';

// const SocialShareKit = require('../plugins/social-share-kit');
import SocialShareKit from '../plugins/social-share-kit';

/* eslint-disable camelcase,func-names,no-plusplus */

window.SocialShareKit = SocialShareKit;

export default () => {
    if (typeof SocialShareKit !== 'undefined') {
        SocialShareKit.init();
        const document_body = $('body');

        // Hide/show popup
        const shareicon = $('.page-options .share .link');
        shareicon.on('click', function () {
            const parent = $(this).parent();
            // Hide
            if (parent.hasClass('active')) {
                parent.removeClass('active');
                document_body.removeClass('js-click-background-touch');
                // Show
            } else {
                parent.addClass('active');
                document_body.addClass('js-click-background-touch');
            }
            return false;
        });
        // Close on background click
        $(document).on('click.socialpopup', (event) => {
            const parent = shareicon.parent();
            // Only run if popup is up
            if (parent.hasClass('active')) {
                // Avoid clicks in popup
                if ($(event.target).closest('.sharing-popup').length === 0) {
                    shareicon.parent().removeClass('active');
                    document_body.removeClass('js-click-background-touch');
                }
            }
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
