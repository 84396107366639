import $ from 'jquery';

const Modernizr = require('../plugins/modernizr.custom.36411');

export default () => {
    // Nth-child support
    if (!Modernizr.lastchild) {
        $('.site-search-filter .field:last-child, .layout-row .layout-col:last-child, .nav-breadcrumb li:last-child, .nav-stepped-anchors li:last-child, #shell .nav-pagination li:last-child, .block-documents-list .block-publication-promo-content:last-child, .nav-landing-list li:last-child, .block-signup .layout-col-3:last-child, .block-landing-1-row .layout-col-6:last-child, .block-landing-3-row .layout-col-3:last-child, .bca-search-results .search-result-item .layout-col:last-child, .codewords-landing-previous-issues .previous-issues-container .layout-col-4:last-child, .homepage .nav-landing-list li:last-child, .homepage .landing-intro.second-row .nav-landing-list li:last-child, .homepage .landing-intro.second-row .layout-col-6:last-child, .campaign-page .updates .layout-col-4:last-child, .resolving-problems-landing-intro .nav-in-this-section li:last-child, .getting-started-landing-intro .nav-stepped-anchors li:last-child, .page-footer .layout-row:last-child').addClass('last');
        $('.js-table-responsive td:nth-child(2), .getting-started-landing-intro .nav-stepped-anchors li:nth-child(2n), .homepage .landing-intro.second-row .nav-landing-list li:nth-child(2n), .nav-stepped-anchors li:nth-child(2n), .codewords-landing-previous-issues .previous-issues-container .layout-col-4:nth-child(2n)').addClass('nth-child-2');
        $('.page-maincontent .nav-tertiary li:nth-child(2n+1), .site-search-filter .field:nth-child(2n+1), .multiproof-search-filter .field:nth-child(2n+1), .product-register-search-filter .field:nth-child(2n+1), .consumer-complaint-search-filter .field:nth-child(2n+1), .determinations-search-filter .field:nth-child(2n+1), .campaign-page .updates .layout-col-4:nth-child(2n+1), .block-landing-2-row .layout-col-4:nth-child(2n+1), .homepage .landing-intro .layout-col-3:nth-child(2n+1)').addClass('nth-child-2-1');
        $('.code-clause-container li:nth-child(3n)').addClass('nth-child-3');
    }
};
