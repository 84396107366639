import Store from '../utils/Store';

/*
 -------------------------------------
 LAYOUT MANAGER
 This detects the layout configuration and
 adjusts/retriggers the JS, as and when required.
 Fires on resize.
 -------------------------------------
 */
export default () => {
    Store.window_width = Store.$window.width();
    Store.window_height = Store.$window.height();

    // Update pinned elements
    Store.$window.trigger('pin_recalculate_sizes');
};
