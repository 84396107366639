import $ from "jquery";

/* -------------------------------------
 OVERFLOW HELPER (adds a scroll button)
 for tablet or mobile users
 Usage:
 $('...').mbie_overflow_helper();
 -------------------------------------
 */
$.fn.mbie_overflow_helper = function () {

  return this.each(function () {

    // Variables
    var panel = $(this),
        scroll_element = panel.find('.overflow-liner'),
        distance = 100,
        trigger_left,
        trigger_right;


    trigger_left = panel.find('.js-scroll-trigger.left');
    trigger_right = panel.find('.js-scroll-trigger.right');
    // Append buttons
    if (trigger_left.length === 0) {
      trigger_left = $('<span class="js-scroll-trigger left"><span class="scroll-button"><span class="icon"></span></span></span>');
      trigger_right = $('<span class="js-scroll-trigger right"><span class="scroll-button"><span class="icon"></span></span></span>');
      panel.append(trigger_left).append(trigger_right);
    } else {
      // remove events
      trigger_left.off('click');
      trigger_right.off('click');
      scroll_element.off('scroll');
    }

    // Calculate width of buttons
    var width_adjustment = (trigger_left.width() * 3);

    // Scroll on click
    trigger_left.on('click', function () {
      // calculate distance to scroll
      distance = (panel.width() - width_adjustment);
      // Scroll
      scroll_element.stop().animate({scrollLeft: '-=' + distance}, '500', 'swing', function () {
        check_visibility(scroll_element, trigger_left, trigger_right);
      });
    });
    trigger_right.on('click', function () {
      // calculate distance to scroll
      distance = (panel.width() - width_adjustment);
      // Scroll
      scroll_element.stop().animate({scrollLeft: '+=' + distance}, '500', 'swing', function () {
        check_visibility(scroll_element, trigger_left, trigger_right);
      });
    });
    // Check visibility on load
    check_visibility(scroll_element, trigger_left, trigger_right);
    // Check visibility on scroll
    scroll_element.on('scroll', function () {
      check_visibility(scroll_element, trigger_left, trigger_right);
    });

  });

  // Function to check button visibility
  function check_visibility(scroll_element, trigger_left, trigger_right) {
    var scroll_position = scroll_element.scrollLeft(),
        parent_width = (scroll_element.children().width() - scroll_element.width());

    // Check left position
    if (scroll_position === 0) {
      trigger_left.removeClass('active');
    } else if (scroll_position > 0) {
      trigger_left.addClass('active');
    }
    // Check right position
    if (scroll_position < parent_width) {
      trigger_right.addClass('active');
    } else if (scroll_position >= parent_width) {
      trigger_right.removeClass('active');
    }
  }


};
