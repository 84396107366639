import $ from 'jquery';
import Store from '../utils/Store';
import Debounce from '../utils/Debounce';
import LayoutManager from './LayoutManager';
import BlockHeights from './BlockHeights';

export default () => {
    // Window Resize event (passed through a debouncer), but not ie8, which fires this continuously
    if (!$('html').hasClass('lt-ie9')) {
        Store.$window.resize(Debounce(() => {
            // Run Layout manager on resize
            LayoutManager();
            BlockHeights($('.block-landing-1, .updates .block-news-item'));
        }, 300));
    }
};
