import $ from 'jquery';

/* -------------------------------------
 CONCERTINA

 Usage:
 <div class="js-concertina">
 <a class="js-trigger" href="#">
 <span class="js-more-text" data-label="Hide">Show</span> details
 </a>
 <div class="js-reveal">The content to reveal</div>
 </div>

 Options that can be passed are the alt button
 label text (eg. data-label="hide/less")
 start as open option (eg. data-state="open")
 -------------------------------------
 */

/*eslint-disable */
$.fn.mbie_concertina = function (options) {

    // Plugin options
    options = $.extend({}, options);

    return this.each(function () {

        // Variables
        var panel_holder = $(this),
            panel_reveal = panel_holder.find('.js-reveal'),
            panel_more_text = panel_holder.find('.js-more-text'),
            panel_trigger = panel_holder.find('.js-trigger'),
            panel_trigger_label = panel_more_text.text(),
            panel_trigger_alt_label = panel_more_text.attr('data-label'),
            panel_trigger_state = panel_trigger.attr('data-state');

        // Generate random ID
        var r = Math.round(Math.random() * 10000);
        var d = new Date();
        var guid = 'id-' + d.getTime() + r.toString();

        // Check for a 'more/less' data label
        if (!panel_trigger_alt_label) {
            panel_trigger_alt_label = 'Less';
        }

        // Set initial aria expanded state
        if (panel_holder.hasClass('active')) {
            panel_trigger.attr('aria-expanded', 'false');
        } else {
            panel_trigger.attr('aria-expanded', 'true');
        }

        // Click Event
        panel_trigger.on('click', function () {

            if (panel_holder.hasClass('active')) {
                panel_holder.removeClass('active');
                // check position
                var panel_top = (panel_holder.offset().top + 50);
                var scroll_position = $(window).scrollTop();
                // Update button text
                panel_more_text.text(panel_trigger_label);
                panel_reveal.stop().fadeOut(function () {
                    // if top of container is off the screen, scroll up
                    if (panel_top < scroll_position) {
                        // Exclude search results concertina
                        if (!panel_trigger.parent().is('.search-menu-results')) {
                            $(window).scrollTop(panel_holder.offset().top);
                        }
                    }
                });
                panel_trigger.attr('aria-expanded', 'false');
            } else {
                panel_holder.addClass('active');
                // Update button text
                panel_more_text.text(panel_trigger_alt_label);
                panel_reveal.stop().fadeIn(function () {
                    // if table is
                    panel_reveal.find('.js-table-wrapper').mbie_overflow_helper();
                });
                panel_trigger.attr('aria-expanded', 'true');
            }
            return false;
        });

        // Close event without animation
        panel_trigger.on('close.noanimation', function () {
            if (panel_holder.hasClass('active')) {
                panel_holder.removeClass('active');
                // Update button text
                panel_more_text.text(panel_trigger_label);
                panel_reveal.hide();
                panel_trigger.attr('aria-expanded', 'false');
                // on mobile, scroll up
                if ($('.mobile-menu').is(':visible')) {
                    $(window).scrollTop(panel_holder.offset().top);
                }
            }
            return false;
        });

        // Open event without animation
        panel_trigger.on('open.noanimation', function () {
            if (!panel_holder.hasClass('active')) {
                panel_holder.addClass('active');
                // Update button text
                panel_more_text.text(panel_trigger_alt_label);
                panel_reveal.show();
                panel_trigger.attr('aria-expanded', 'true');
            }
            return false;
        });

        // Check state 9does it need to be opened on load?)
        if (panel_trigger_state === 'open') {
            panel_trigger.trigger('click');
        }

        // Accessibility
        panel_reveal.attr('id', guid);
        panel_trigger.attr('aria-controls', guid);
    });
};
/* eslint-enable */
