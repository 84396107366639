import $ from 'jquery';

/* -------------------------------------
   AJAX SPINNER
   Usage: AjaxSpinner('element', 'show|hide');
   -------------------------------------
   */
export default (elements, action) => {
    if (elements && action) {
        $(elements).each((i, el) => {
            // Add
            if (action === 'show') {
                if ($(el).find('.ajax-spinner').length === 0) {
                    $(el).append('<div class="ajax-spinner">&nbsp;</div>');
                }
                // Hide
            } else {
                $(el).find('.ajax-spinner').remove();
            }
        });
    }
};
