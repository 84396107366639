import $ from 'jquery';
import AjaxSpinner from './AjaxSpinner';
/* eslint-disable camelcase,func-names,no-plusplus,consistent-return */

/* -------------------------------------
 GALLERY MODAL
 -------------------------------------
 */
export default () => {
    const modal = $('#gallery-modal');
    if (modal.length > 0) {
        // Show gallery (ie. the data source)
        $('.enlarge-image a').on('click', function () {
            // Source variables
            const source = $(this);
            const source_image = source.find('img');
            const source_image_src = source.attr('href');
            const source_image_alt = source_image.attr('alt');
            const source_image_title = source_image.attr('title');
            const source_image_credit = source.find('.credit').text();
            const source_image_caption = source.find('.caption').html();

            // Modal variables
            const modal_image = modal.find('.image');
            const modal_title = modal.find('.title');
            const modal_caption = modal.find('.caption');
            const modal_credit = modal.find('.credit');

            if (!modal.hasClass('modal-active')) {
                // Empty modal

                modal_image.empty();
                modal_title.empty();
                modal_caption.empty();
                modal_credit.empty();

                // Populate image details
                if (source_image_title) {
                    if (source_image_title.length > 0) {
                        modal_title.html(source_image_title);
                    }
                }
                if (source_image_caption) {
                    if (source_image_caption.length > 0) {
                        modal_caption.html(source_image_caption);
                    }
                }
                if (source_image_credit) {
                    if (source_image_credit.length > 0) {
                        modal_credit.html(source_image_credit);
                    }
                }
                // Show modal
                modal.stop().fadeIn().addClass('modal-active');
                // Move focus into modal
                modal.find('a:first').focus().blur();
                // Load image
                AjaxSpinner(modal_image, 'show');
                const new_image = $(`<img alt="${source_image_alt}" src="${source_image_src}"/>`).on('load', function () {
                    if (this) {
                        modal_image.html(new_image);
                    } else {
                        modal_image.html('Oops, there was a problem loading your image.');
                    }
                });

                // Close on escape
                $(document).off('keyup.gallerymodal');
                $(document).on('keyup.gallerymodal', (event) => {
                    if (event.keyCode === 27) {
                        if (modal.hasClass('modal-active')) {
                            modal.find('.close-modal').trigger('click');
                        }
                    }
                });

                return false;
            }
        });

        // Hide gallery
        modal.find('.close-modal').on('click', () => {
            if (modal.hasClass('modal-active')) {
                modal.stop().fadeOut().removeClass('modal-active');
                // Close on escape
                $(document).off('keyup.gallerymodal');
            }
            return false;
        });

        // Show caption
        const image_details = modal.find('.image-details .liner');
        modal.find('.show-image-details').on('click', () => {
            if (image_details.hasClass('active')) {
                image_details.removeClass('active').stop().fadeOut('fast');
            } else {
                image_details.addClass('active').stop().fadeIn('fast');
            }
            return false;
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus,consistent-return */
