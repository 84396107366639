import $ from "jquery";
import Debounce from "../utils/Debounce";
import Store from '../utils/Store';

/* -------------------------------------
 JQUERY PIN
 Pinning inside a container.
 http://webpop.github.io/jquery.pin/

 Note: contains several bug fixes by AK
 (look for AK prefix)
 -------------------------------------
 */
$.fn.pin_to_container = function (options) {
  var scrollY = 0, elements = [], disabled = false, $window = Store.$window;

  options = options || {};

  var recalculateLimits = function () {
    for (var i = 0, len = elements.length; i < len; i++) {
      var $this = elements[i];

      // Disable on small screens
      if (options.minWidth && $window.width() <= options.minWidth) {
        if ($this.parent().is(".pin-wrapper")) {
          $this.unwrap();
        }
        $this.css({width: "", left: "", top: "", position: ""});
        if (options.activeClass) {
          $this.removeClass(options.activeClass);
        }
        disabled = true;
        continue;
      } else {
        disabled = false;
      }

      // reset before doing any calculations
      $this.css({position: "", top: "", left: "", 'margin-top': options.marginTop});

      var $container = options.containerSelector ? $this.closest(options.containerSelector) : $(document.body);
      var offset = $this.offset();
      var containerOffset = $container.offset();
      var parentOffset = $this.offsetParent().offset();

      if (!$this.parent().is(".pin-wrapper")) {
        $this.wrap("<div class='pin-wrapper'>");
      }

      var pad = $.extend({
        top: 0,
        bottom: 0
      }, options.padding || {});


      $this.data("pin", {
        pad: pad,
        from: (options.containerSelector ? containerOffset.top : offset.top) - pad.top,
        to: containerOffset.top + $container.height() - $this.outerHeight() - pad.bottom,
        end: containerOffset.top + $container.height(),
        parentTop: parentOffset.top
      });

      $this.css({'margin-top': 0}); // AK: added margin-top reset to fix margin bug
      $this.css({width: 'auto', position: 'static'}); // AK: added position-static to fix width bug
      $this.css({width: $this.outerWidth()});
      $this.parent().css("height", $this.outerHeight());

    }
  };

  var onScroll = function () {
    if (disabled) {
      return;
    }

    scrollY = $window.scrollTop();

    var elmts = [];
    for (var i = 0, len = elements.length; i < len; i++) {
      var $this = $(elements[i]),
          data = $this.data("pin");

      if (!data) { // Removed element
        continue;
      }

      elmts.push($this);

      var from = data.from - data.pad.bottom,
          to = data.to - options.marginTop; // AK: bug fix (this is the margin-height of $this)

      if (from + $this.outerHeight() > data.end) {
        $this.css('position', '');
        continue;
      }

      // If in the container, fix it
      if (from < scrollY && to > scrollY) {
        if ($this.css("position") !== "fixed") {
          $this.css({
            left: $this.offset().left,
            top: data.pad.top
          }).css("position", "fixed");
        }
        if (options.activeClass) {
          $this.addClass(options.activeClass);
        }
        // If above the zone, scroll it, but keep pinned
      } else if (scrollY >= to) {
        $this.css({
          left: "",
          top: to - data.parentTop + data.pad.top
        }).css("position", "absolute");
        if (options.activeClass) {
          $this.addClass(options.activeClass);
        }

        // Inactive
      } else {
        $this.css({
          position: "", top: "", left: "",
          'margin-top': options.marginTop
        });
        if (options.activeClass) {
          $this.removeClass(options.activeClass);
        }
      }
    }
    elements = elmts;
  };

  var update = function () {
    recalculateLimits();
    onScroll();
  };

  this.each(function () {
    var $this = $(this),
        data = $(this).data('pin') || {};

    if (data && data.update) {
      return;
    }
    elements.push($this);
    $("img", this).one("load", recalculateLimits);
    data.update = update;
    $(this).data('pin', data);
  });

  $window.scroll(onScroll);
  $window.resize(Debounce(function () {
    recalculateLimits();
    onScroll();
  }, 300));
  recalculateLimits();

  $window.load(update);

  return this;
};
