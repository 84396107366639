import $ from 'jquery';

/* -------------------------------------
 SEARCH MENU
 -------------------------------------
 */
/* eslint-disable camelcase,func-names,no-plusplus */

export default () => {
    const page_header = $('.page-header');
    const site_search_container = $('.site-search-container');
    const $document = $(document);
    const document_body = $('body');

    // Add close
    site_search_container.append('<span class="icon-cross menu-close"></span>');

    // Mobile search - click
    page_header.find('.mobile-search').on('click', () => {
        document_body.addClass('mobile-search-active');
        return false;
    });
    // Close mobile search
    site_search_container.find('.menu-close').on('click', () => {
        document_body.removeClass('mobile-search-active');
        return false;
    });

    // Reusable open function for different events below
    const open_menu = () => {
        document_body.addClass('js-click-background-touch');
        site_search_container.addClass('search-active');
        // Close search menu
        // Background click
        $document.off('click.searchmenu');
        $document.on('click.searchmenu', (event) => {
            // Avoid clicks in popup
            if ($(event.target).closest('.site-search-container').length === 0) {
                site_search_container.removeClass('search-active');
                document_body.removeClass('js-click-background-touch');
                $document.off('click.searchmenu');
                $document.off('keyup.searchmenu');
            }
        });
        // Escape key
        $document.off('keyup.searchmenu');
        $document.on('keyup.searchmenu', (event) => {
            if (event.keyCode === 27) {
                if (site_search_container.hasClass('search-active')) {
                    site_search_container.removeClass('search-active');
                    document_body.removeClass('js-click-background-touch');
                    $document.off('keyup.searchmenu');
                    $document.off('click.searchmenu');
                }
            }
        });
    };

    // Open search menu on mousedown
    site_search_container.find('input.text').on('mousedown', open_menu);

    // Open search menu on down arrow
    site_search_container.find('input.text').on('keydown', (e) => {
        if (e.keyCode === 40) {
            open_menu();
        }
    });
};
/* eslint-enable camelcase,func-names,no-plusplus */
