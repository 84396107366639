import $ from 'jquery';
/* eslint-disable camelcase,func-names,no-plusplus */

/* -------------------------------------
 BLOCK HEIGHT ADJUSTER
 Note: this function adjusts the height of all floated blocks that have a background colour
 on a page and is run on resize and load.
 -------------------------------------
 */
export default (lm_landing_page_blocks_1) => {
    // lm_landing_page_blocks_1
    if (lm_landing_page_blocks_1.length > 0) {
        // Adjust min-height of all landing page floated blocks that require it
        // (only necessary on blocks that have percentage based widths, so as to achieve the design)
        let max_height_block_1; let
            item_height_1 = 0;
        // Get max height on all blocks
        lm_landing_page_blocks_1.each(function () {
            $(this).css('height', 'inherit').removeClass('height-adjusted');
            item_height_1 = $(this).height();
            max_height_block_1 = max_height_block_1 > item_height_1 ? max_height_block_1 : item_height_1;
        });
        // Block style 1
        lm_landing_page_blocks_1.each(function () {
            const block = $(this);
            // only if floated
            if (block.parent().css('float') === 'left') {
                block.css('height', max_height_block_1).addClass('height-adjusted');
            } else {
                block.css('height', 'inherit').removeClass('height-adjusted');
            }
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
