import $ from 'jquery';
import AjaxSpinner from './AjaxSpinner';
/* eslint-disable camelcase,func-names,no-plusplus */

/* -------------------------------------
 PRINT FUNCTIONS
 -------------------------------------
 */
export default () => {
    // Print page button
    const print_icon = $('.print-this-page, #print-modal .print-page');
    if (print_icon.length > 0) {
        print_icon.on('click', () => {
            print_icon.blur();

            // Hide modal when printing
            const gallery_modal_up = $('.gallery-modal').is(':visible');
            if (gallery_modal_up) {
                $('html').addClass('print-gallery-modal');
            }

            window.print();

            if (gallery_modal_up) {
                $('html').removeClass('print-gallery-modal');
            }

            return false;
        });

        // Print all guidance overview and reading documents
        $('#print-modal .print-all-documents').on('click', function () {
            const button = $(this);
            // Set spinner
            AjaxSpinner(button, 'show');
            // Get content and append to page
            $('#ajax-container').load(button.attr('href'), () => {
                $('#content').hide();
                AjaxSpinner(button, 'hide');
                // Print
                window.print();
                // Empty appended content
                $('#ajax-container').empty();
                $('#content').show();
                button.blur();
            });
            return false;
        });
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
