import $ from 'jquery';

const Modernizr = require('../plugins/modernizr.custom.36411');
/* eslint-disable camelcase,func-names,no-plusplus */

// TODO review sessionStorage
export default () => {
    // Notification block (need to add ID)
    if ($('.block-notification').length > 0) {
        const block_notification = $('.block-notification');
        // Add close event
        block_notification.find('.close-notification').on('click', () => {
            block_notification.slideUp();
            sessionStorage.notification_id = block_notification.attr('data-id');
            return false;
        });
        // Add session
        if (Modernizr.sessionstorage) {
            if (sessionStorage.notification_id === block_notification.attr('data-id')) {
                block_notification.hide();
            }
        }
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
