import $ from "jquery";
import Store from '../utils/Store';

/* -------------------------------------
 JQUERY PIN
 Author: AK
 Pinning with conditional boundaries.

 Note: Not related to the online version below!

 Works best with a boundary element. IF unknown, use the
 $(document). Don't use this plugin if just pinning.
 Do use this plugin if pinning inside a container.
 -------------------------------------
 */
$.fn.pin = function (options) {

  // Variables
  var element_to_reveal, scroll_top, window_height, boundary_top, boundary_bottom, element_height,
      boundary_element = false, disabled = false;

  // Plugin options
  options = $.extend({
    boundary_element: '',
    pinned_element: '',
    offset_top: false,
    pinned_element_affects_page_height: false
  }, options);

  // Scroll function
  var onScroll = function () {
    if (disabled) {
      return;
    }
    // Update the various variables, as these may change on the fly
    scroll_top = Store.$window.scrollTop();
    var element_has_class = element_to_reveal.hasClass('pinned');

    // Calculate top boundary
    boundary_top = Math.round(boundary_element.offset().top);
    // Check for offset
    if (options.offset_top) {
      boundary_top = (boundary_top + options.offset_top);
    }

    // Calculate bottom boundary
    boundary_bottom = (Math.round(boundary_element.offset().top) + (boundary_element.outerHeight() + 1));

    // if pinned element affects the height of the page...
    if (options.pinned_element_affects_page_height) {
      boundary_bottom = (boundary_bottom + element_height);
    }

    // If scroll has reached bottom of the boundary, then unpin
    if (boundary_bottom <= (scroll_top + window_height)) {
      if (element_has_class) {
        element_to_reveal.removeClass('pinned');
      }
      // If scroll has reached the top of the boundary, then unpin
    } else if (scroll_top <= boundary_top) {
      if (element_has_class) {
        element_to_reveal.removeClass('pinned');
      }
    } else {
      if (!element_has_class) {
        element_to_reveal.addClass('pinned');
      }
    }
  };

  return this.each(function () {

    element_to_reveal = $(this);
    window_height = Store.$window.height();

    // Check for boundary element
    if (options.boundary_element) {
      boundary_element = $(options.boundary_element);
    } else {
      boundary_element = element_to_reveal.prev();
    }
    // Check if the height of the pinned element is larger because of hidden content (eg. action-bar)
    if (options.pinned_element) {
      element_height = $(options.pinned_element).height();
    } else {
      element_height = element_to_reveal.height();
    }

    // Bind scroll stop event that determines if the tab should be shown, or not
    Store.$window.bind('scroll.pin', function () {
      onScroll();
    });

    // Fire on load (fires anyway?)
    //onScroll();

    // Recalculate limits
    // - this is fired more often than would otherwise
    // be necessary, as the page may resize often.
    // Triggered by an event to allow
    // for external calls.
    Store.$window.bind('pin_recalculate_sizes', function () {
      // Update variables
      window_height = Store.$window.height();
      scroll_top = Store.$window.scrollTop();
      boundary_bottom = ((Math.round(boundary_element.offset().top) + boundary_element.outerHeight()) + element_height);
      onScroll();
    });
  });
};
