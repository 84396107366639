import $ from 'jquery';
/* eslint-disable camelcase,func-names,no-plusplus,no-lonely-if,consistent-return */
/* -------------------------------------
 MAIN MENU
 Note: this acts on both the desktop
 meganav as well as the mobile nav.
 -------------------------------------
 */
export default (nav) => {
    const lm_mobile_menu = $('.mobile-menu');

    // First click event
    nav.find('li a').on('click', function () {
        const nav_item = $(this);
        const nav_parent = nav_item.parent();
        const nav_children = nav_item.next('.child');

        // If meganav
        let meganav = 0;
        meganav = nav_parent.find('.nav-mega');

        // Close menu
        if (nav_parent.hasClass('active')) {
            nav_parent.removeClass('active');
            nav_item.blur();
            if (meganav.length > 0) {
                meganav.stop().fadeOut();
                $(document).off('keyup.meganav');
                $(document).off('click.meganav');
            }
            // Open menu
        } else {
            if (meganav.length > 0) {
                nav.find('.active').removeClass('active').find('.nav-mega').stop()
                    .fadeOut();
                meganav.stop().fadeIn();
                // Close on escape
                $(document).off('keyup.meganav');
                $(document).on('keyup.meganav', (event) => {
                    if (event.keyCode === 27) {
                        nav.find('.active .first-level').trigger('click');
                    }
                });
                // Close on background click
                $(document).off('click.meganav');
                $(document).on('click.meganav', (event) => {
                    // Avoid clicks in panel
                    if ($(event.target).closest('.nav-mega').length === 0) {
                        nav.find('.active .first-level').trigger('click');
                    }
                });
            } else {
                // Add additional check for mobile menu
                if (nav_item.hasClass('js-back')) {
                    nav.find('.active').removeClass('active');
                }
            }
            // Add additional check for mobile menu
            if (!nav_item.hasClass('js-back')) {
                nav_parent.addClass('active');
            }
        }

        // Close mobile menu if open
        if ((lm_mobile_menu.hasClass('active')) && (nav_item.closest('#nav-main').length === 1)) {
            lm_mobile_menu.trigger('click');
        }

        // Don't halt click events if they are children, or if in desktop mode
        if ((nav_children.length > 0) || (nav_item.hasClass('js-back'))) {
            return false;
        }
    });
};
/* eslint-enable camelcase,func-names,no-plusplus,no-lonely-if,consistent-return */
