import $ from 'jquery';
/* eslint-disable camelcase,func-names,no-plusplus */

/* -------------------------------------
 MOBILE MENU
 -------------------------------------
 */
export default () => {
    const nav_main = $('#nav-main');
    const lm_mobile_menu = $('.mobile-menu');
    const mobile_menu_container = $('#mobile-menu-container');
    const document_body = $('body');

    // Clone menu
    nav_main.clone(true).appendTo(mobile_menu_container).attr('id', '').removeClass('nav-main');
    // Add close
    mobile_menu_container.append('<span class="icon-cross menu-close"></span>');

    // Temporary - remove the meganav
    mobile_menu_container.find('.nav-mega').remove();

    // Add back links to child menus
    mobile_menu_container.find('ul.child').each(function () {
        const child = $(this);
        const text_label = child.prev().text();
        child.prepend('<li class="back-link"><a class="js-back" href="#"></a></li>');
        child.find('.js-back').text(text_label);
    });

    // Mobile menu - click
    lm_mobile_menu.on('click', function () {
        document_body.addClass('mobile-menu-active');
        $(this).addClass('active');
        return false;
    });
    // Close mobile menu
    mobile_menu_container.find('.menu-close').on('click', () => {
        document_body.removeClass('mobile-menu-active');
        mobile_menu_container.find('.active').removeClass('active');
        return false;
    });

    // Tablet helper (adds a span to help with the chevron
    // breaking in odd places on tablet)
    nav_main.find('li a.first-level').each(function () {
        const link_el = $(this);
        // Split text
        const link_text_array = link_el.text().split(/\s+/);
        // Get the last word
        const last_word = link_text_array.pop();
        const first_part = link_text_array.join(' ');
        // Wrap in a non-breaking span and reinstate text;
        link_el.html(`${first_part} <span>${last_word}</span>`);
    });
};
/* eslint-enable camelcase,func-names,no-plusplus */
