/* eslint-disable consistent-return, brace-style */

const defaults = {
    tabListSelector: '.block-code-nav-bar__inner',
    tabWrapperSelector: '.block-code-nav-bar__inner:first-child',
    tabSelector: '.block-code-nav-bar__inner:first-child > .code-section > a.code-button',
    default: 'data-tabs__default',
};

export default () => {
    const tabsets = document.querySelectorAll('[data-tabs]');

    if (tabsets.length === 0) {
        return;
    }

    /**
     * Get ID of a tab from a string
     *
     * @param value
     */
    const getTabId = (value) => {
        const segments = value.split('#');

        return segments[1] !== undefined ? `#${segments[1]}` : segments[0];
    };

    tabsets.forEach((el) => {
        const tabListSelector = el.querySelector(defaults.tabListSelector);
        const tabWrapper = el.querySelector(defaults.tabWrapperSelector);
        const tabs = el.querySelectorAll(defaults.tabSelector);

        /**
         * Add the required roles, attributes and initial state to a tab and its content
         * @param  {Node}   tab      The tab
         * @param  {Node}   content  The tab content
         */
        const setupTab = (tab, content) => {
            tab.setAttribute('role', 'tab');
            tab.setAttribute(
                'aria-controls',
                tab.getAttribute('href').substr(1),
            );

            // Parent li
            tab.parentElement.setAttribute('role', 'presentation');
            content.setAttribute('role', 'tabpanel');
            content.setAttribute('aria-labelledby', tab.getAttribute('id'));

            // Apply selected state
            if (tab.hasAttribute(defaults.default)) {
                tab.setAttribute('aria-selected', 'true');
                tab.setAttribute('aria-expanded', 'true');
            } else {
                tab.setAttribute('aria-selected', 'false');
                tab.setAttribute('aria-expanded', 'false');
                content.setAttribute('hidden', 'hidden');
            }
        };

        /**
         * Hide a tab and its content
         */
        const hide = () => {
            // Variables
            const tabGroup = tabListSelector;
            if (!tabGroup) return;
            const tab = tabGroup.querySelector(
                '[role="tab"][aria-selected="true"]',
            );
            if (!tab) return;
            const content = document.querySelector(tab.hash);

            tabGroup.removeAttribute('data-active');
            // Hide the tab
            tab.setAttribute('aria-selected', 'false');
            tab.setAttribute('aria-expanded', 'false');
            tab.setAttribute('tabindex', '-1');
            // Hide the content
            content.setAttribute('hidden', 'hidden');
        };

        /**
         * Show a tab and its content
         * @param  {Node} tab      The tab
         * @param  {Node} content  The tab content
         */
        const show = (tab, content) => {
            const tabGroup = tabListSelector;
            tabGroup.setAttribute('data-active', 'true');
            tab.setAttribute('aria-selected', 'true');
            tab.setAttribute('aria-expanded', 'true');
            tab.setAttribute('tabindex', '0');
            content.removeAttribute('hidden');
            tab.focus();
        };

        /**
         * Disable focus on all tabs if no tabindex is set initially - this is
         * applicable when there is no default tab. Used on click/navigation.
         */
        const disableTabsFocus = () => {
            tabs.forEach((tab) => {
                if (!tab.hasAttribute('tabindex')) {
                    tab.setAttribute('tabindex', '-1');
                }
            });
        };

        /**
         * Enable focus on all tabs if no tabindex is set initially - this is
         * applicable when there is no default tab. Used when a tab is clicked on
         * once already open.
         */
        const enableTabsFocus = () => {
            tabs.forEach((tab) => {
                tab.removeAttribute('tabindex');
            });
        };

        /**
         * Toggle a new tab
         * @param  {Node} tab The tab to show
         */
        const toggle = (tab) => {
            // Make sure there's a tab to toggle and it's not already active
            if (!tab) return;

            // Variables
            const content = document.querySelector(tab.hash);
            if (!content) return;

            // If tab is selected, hide tab and enable tab focus
            if (tab.getAttribute('aria-selected') === 'true') {
                hide(tab, content);
                enableTabsFocus();

                return;
            }

            // Hide active tab and content
            hide(tab, content);

            // Show new tab and content
            show(tab, content);
        };

        /**
         * Get all of the tabs in a tablist
         * @param  {Node}   tab  A tab from the list
         * @return {Object}      The tabs and the index of the currently active one
         */
        const getTabsMap = (tab) => {
            const tabGroup = tabListSelector;
            const tabItems = tabGroup
                ? tabGroup.querySelectorAll('[role="tab"]')
                : null;

            if (!tabItems) return;

            return {
                tabItems,
                index: Array.prototype.indexOf.call(tabItems, tab),
            };
        };

        /**
         * Switch the active tab based on keyboard activity
         * @param  {Node} tab The currently active tab
         * @param  {Key}  key The key that was pressed
         */
        const switchTabs = (tab, key) => {
            // Get a map of tabs
            const map = getTabsMap(tab);
            if (!map) return;
            const length = map.tabItems.length - 1;
            let index;

            // Go to previous tab
            if (['ArrowLeft', 'Left'].indexOf(key) > -1) {
                index = map.index < 1 ? length : map.index - 1;
            }

            // Go to next tab
            else if (['ArrowRight', 'Right'].indexOf(key) > -1) {
                index = map.index === length ? 0 : map.index + 1;
            }

            // Go to home
            else if (key === 'Home') {
                index = 0;
            }

            // Go to end
            else if (key === 'End') {
                index = length;
            }

            // Toggle the tab
            toggle(map.tabItems[index]);
        };

        /**
         * Handle click events
         */
        const clickHandler = (event) => {
            // Only run on toggles
            let tab = event.target;
            if (
                event.target.getAttribute('role') !== 'tab'
                && event.target.parentElement.getAttribute('role') !== 'tab'
            ) return;

            if (event.target.getAttribute('role') === 'tab') {
                tab = event.target;
            } else if (event.target.parentElement.getAttribute('role') === 'tab') {
                tab = event.target.parentElement;
            }

            // Prevent link behavior
            event.preventDefault();

            disableTabsFocus();

            // Toggle the tab
            toggle(tab);
        };

        /**
         * Handle keydown events
         */
        const keyHandler = (event) => {
            // Only run if a tab is in focus
            const tab = document.activeElement;

            if (tab.getAttribute('role') !== 'tab') return;

            // Only run for specific keys
            if (
                [
                    'ArrowLeft',
                    'ArrowRight',
                    'Left',
                    'Right',
                    'Home',
                    'End',
                ].indexOf(event.key) < 0
            ) {
                return;
            }

            disableTabsFocus();

            // Switch tabs
            switchTabs(tab, event.key);
        };

        // Setup
        tabWrapper.setAttribute('role', 'tablist');

        tabs.forEach((tab) => {
            const tabHref = getTabId(tab.getAttribute('href'));
            const content = el.querySelector(`${tabHref}`);

            setupTab(tab, content);

            // Change tab to window hash if it exists
            if (window.location.hash && tabHref === window.location.hash) {
                toggle(tab);
            }
        });

        // Add click event listener
        tabWrapper.addEventListener(
            'click',
            event => clickHandler(event),
            true,
        );
        // Add key event listener
        tabWrapper.addEventListener(
            'keydown',
            event => keyHandler(event),
            true,
        );
    });
};
