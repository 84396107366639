// Polyfill for IE
import '@babel/polyfill';
import objectFitImages from 'object-fit-images';

import $ from 'jquery';
import './utils/LoadJQuery';
import Store from './utils/Store';

import Accordion from './components/Accordion';
import AjaxForm from './components/AjaxForm';
import JsClass from './components/JsClass';
import BlockHeights from './components/BlockHeights';
import Fancybox from './components/Fancybox';
import GalleryModal from './components/GalleryModal';
import LayoutManager from './components/LayoutManager';
import MainMenu from './components/MainMenu';
import Masonry from './components/Masonry';
import MobileMenu from './components/MobileMenu';
import NotificationBlock from './components/NotificationBlock';
import NthChildHelper from './components/NthChildHelper';
import Print from './components/Print';
import RatingFeedback from './components/RatingFeedback';
import SearchMenu from './components/SearchMenu';
import SecondaryMenu from './components/SecondaryMenu';
import SocialShareKit from './components/SocialShareKit';
import StyleHelper from './components/StyleHelper';
import Tabs from './components/Tabs';
import TertiaryMenu from './components/TertiaryMenu';
import WindowResizeHelper from './components/WindowResizeHelper';

// Polyfills for array methods
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

require('./plugins/LatexIT');

// Jquery UI for tabs
require('jquery-ui/ui/version');
require('jquery-ui/ui/plugin');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/position');
require('jquery-ui/ui/data');
require('jquery-ui/ui/disable-selection');
require('jquery-ui/ui/focusable');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/labels');
require('jquery-ui/ui/scroll-parent');
require('jquery-ui/ui/tabbable');
require('jquery-ui/ui/unique-id');
require('jquery-ui/ui/widgets/tabs');

JsClass();

function init() {
    // Used to add class for focus states when using keyboard
    Store.$body = $('body');
    Store.$window = $(window);
    Store.window_width = Store.$window.width();
    Store.window_height = Store.$window.height();

    NthChildHelper();
    StyleHelper();
    // Setup the menus
    MobileMenu();
    SearchMenu();
    MainMenu($('#nav-main'));
    MainMenu($('#mobile-menu-container'));
    // Rating feedback
    RatingFeedback.Init();
    RatingFeedback.Update();
    // Secondary menu (collapsible)
    SecondaryMenu();
    // Setup the tertiary nav
    TertiaryMenu();

    // Gallery modal
    GalleryModal();

    // Generic modal
    Fancybox();

    // Print
    Print();

    // Window Resize event (passed through a debouncer), but not ie8, which fires this continuously
    WindowResizeHelper();

    // Run Layout manager on load
    LayoutManager();

    // Social
    SocialShareKit();

    // Notification block (need to add ID)
    NotificationBlock();

    // AJAX forms
    AjaxForm();

    // Masonry layout
    Masonry();

    Tabs();

    Accordion();

    objectFitImages('.homepage-hero__image');
}

function initLoad() {
    BlockHeights($('.block-landing-1, .updates .block-news-item'));
    // Masonry layout
    Masonry();
}

// wait for load, then start initiation
$(document).ready(init);
// Run the block height adjust again, after any images have loaded
$(window).load(initLoad);
