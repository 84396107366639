import $ from "jquery";

/* -------------------------------------
 TABLE HELPER
 Runs a number of helpers for viewing
 large tables, for both desktop users and
 mobile devices.

 Notes about Marks IA:
 It assumes that the first column is the most important. The system fails if this is just an ID, or icon, or some mneaningless field.
 -------------------------------------
 */
$.fn.mbie_tablehelper = function (options) {

  // Plugin options
  options = $.extend({
    'limit': 5
  }, options);

  return this.each(function () {

    // Variables
    var table = $(this),
        thead = table.find('thead'),
        theaders = thead.find('th'),
        trows = table.find('tbody tr'),
        tdata = table.find('td'),
        table_wrapper;

    // Wrap table
    table.wrap('<div class="js-table-wrapper"></div>');
    table_wrapper = table.parent();
    table.wrap('<div class="overflow-liner"></div>');

    // If rows exceed limit, hide them
    if (trows.length > options.limit) {
      // Append view more button
      table_wrapper.append('<div class="view-full-table"><a href="#"><span class="icon">+</span><span class="text">View full table</span></a></div>');
      // Hide additional rows
      trows.filter(function (index) {
        if (index > (options.limit - 1)) {
          $(this).addClass('js-hidden');
        }
      });
    }

    // Click event to view all
    table_wrapper.find('.view-full-table a').on('click', function () {
      table.find('tbody tr.js-hidden').attr('aria-live', 'assertive');
      trows.removeClass('js-hidden');
      $(this).parent().hide();
      return false;
    });


    // Tables that exceed available width, add helper arrows
    table_wrapper.mbie_overflow_helper();


    // Mobile tables
    if (thead.length > 0) {
      table.addClass('js-table-responsive');

      // Mobile: Loop through each table header and copy the header to the corresponding row in the table body, to act as a more localised header. CSS then renders cells as blocks in a vertical stack.
      trows.each(function () {
        $(this).find('td').each(function (index) {
          var cell = $(this);
          if (index === 0) {
            cell.append('<span class="js-show icon-plus"></span>');
          }
          cell.before(theaders.eq(index).clone().addClass('js-cell-responsive'));
        });
      });

      // Mobile: Click to reveal hidden cells
      trows.find('.js-show').on('click', function () {
        var row = $(this).parent().parent();
        if (row.hasClass('active')) {
          row.removeClass('active');
        } else {
          row.addClass('active');
        }
      });
    }

  });
};
