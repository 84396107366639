import $ from 'jquery';
import select2 from 'select2';
import Store from '../utils/Store';
import AjaxNav from './AjaxNav';
import ClearForm from './ClearForm';
import AjaxSpinner from './AjaxSpinner';
import '../plugins/RadioBtn';
import '../plugins/OverflowHelper';
import '../plugins/PinToContainer';
import '../plugins/Pin';
import '../plugins/MobileTable';
import '../plugins/Concertina';

/*eslint-disable */
/* -------------------------------------
 STYLE HELPERS
 Accordians, select dropdowns etc.
 -------------------------------------
 */
export default () => {
    var internal = {};
    internal.window_width = Store.$window.width();
    internal.window_height = Store.$window.height();

    // Init select2
    $(document).ready(function() {
        $('select').select2();
    });


    // Pinned elements
    if ($('.building-code-section .nav-secondary').length === 0) {
        $('.building-code-section .nav-secondary').pin_to_container({
            'containerSelector': '#content',
            'minWidth': 800,
            'padding': {
                'top': 0,
                'bottom': 0
            },
            'marginTop': 24
        });
    }
    $('.building-code-clause .aside-left, .building-code-section .aside-left').pin_to_container({
        'containerSelector': '#content',
        'minWidth': 800,
        'padding': {
            'top': 0,
            'bottom': 0
        },
        'marginTop': 0
    });

    $('.link-back-to-top').pin({
        'boundary_element': '#shell',
        'offset_top': internal.window_height
    });

    // Tabs
    if ($('.codewords-landing-previous-issues').length > 0) {
        $('.codewords-landing-previous-issues').tabs();
    }

    // Table helper
    $('table').mbie_tablehelper();

    // Faux selects
    if ($('form select').length > 0) {
        $('.page-maincontent select').select2();

        $('.search-filter-dropdown .select2-selection__rendered').attr('title', 'Select a topic');
    }

    // Faux Radio/checkbox buttons styles
    $('form input[type=radio], form input[type=checkbox]').faux_radio_inputs();

    // text input clearer icon
    if ($('form').length > 0) {
        $('.page-maincontent input.text:not(:disabled):not([data-do-not-enhance]), .page-maincontent textarea:not(:disabled):not([data-do-not-enhance])').each(function () {
            var input = $(this);
            // Wrap
            input.wrap('<div class="form-wrap" />');
            var parent = input.parent('.form-wrap');
            // Append icon
            parent.addClass('js-clear').append('<span class="icon-close" />');
            var clear_icon = parent.children('.icon-close');
            // Click event
            clear_icon.on('click', function () {
                // Clear
                input.val("");
                parent.addClass('active');
            });
            // Type event (only show if there's content
            input.on('keyup', function () {
                if (input.val() === "") {
                    parent.addClass('active');
                } else {
                    parent.removeClass('active');
                }
            });
            // On load
            if (input.val() === "") {
                parent.addClass('active');
            } else {
                parent.removeClass('active');
            }
        });
    }

    $('section.content-wrapper img').each(function() {
        var $this = $(this);
        var title = $this.attr('title');
        var alt = $this.attr('alt');
      
        if (title && (!alt || alt === '')) {
          $this.attr('alt', title);
        }
    });

    // Clear form helper
    if ($('.search-filter').length > 0) {
        $('.search-filter .filter-reset').on('click', function () {
            var trigger = $(this);
            // clear form
            ClearForm(trigger.closest('form'));
        });
        // Autosubmit on sort change (test)
        $('.search-sort select').on('change', function () {
            AjaxSpinner($(this).closest('.field').find('label'), 'show');
            $(this).closest('form').submit();
        });
    }


    // Concertinas
    var concertinas = $('.js-concertina');
    if (concertinas.length > 0) {

        // Run plugin
        concertinas.mbie_concertina();

        // Trigger all concertinas (without animation)
        if ($('.js-trigger-all').length > 0) {
            $('.js-trigger-all').on('click', function () {
                var button = $(this),
                    panel_more_text = button.find('.js-more-text');
                if (button.hasClass('active')) {
                    button.removeClass('active');
                    // Update button text
                    panel_more_text.text('Show');
                    // Trigger child concertinas
                    if (button.closest('.js-concertina-group').length > 0) {
                        button.closest('.js-concertina-group').find('.js-trigger').trigger('close.noanimation');
                    } else {
                        concertinas.find('.js-trigger').trigger('close.noanimation');
                    }
                } else {
                    button.addClass('active');
                    // Update button text
                    panel_more_text.text('Hide');
                    // Trigger child concertinas
                    if (concertinas.closest('.js-concertina-group').length > 0) {
                        button.closest('.js-concertina-group').find('.js-trigger').trigger('open.noanimation');
                    } else {
                        concertinas.find('.js-trigger').trigger('open.noanimation');
                    }
                }
                return false;
            });
        }

    }

    // Ajaxed nav
    AjaxNav();

    // Breadcrumb reveal for mobile
    var crumbs = $('.nav-breadcrumb li');
    // Only add if longer than 3 items
    if (crumbs.length > 2) {
        // Add trigger
        crumbs.filter(':eq(0)').after('<li class="js-expand"><a href="#"><span>...</span></a></li>');
        // Hide others
        crumbs.filter(':gt(0)').addClass('inactive');
        // Show last
        crumbs.filter(':last-child').removeClass('inactive');
        // Click event
        $('.nav-breadcrumb .js-expand a').on('click', function () {
            $(this).parent().hide();
            crumbs.css('display', 'inline-block');
            return false;
        });
    }

    // Setup BCA A to Z tablet overflow helper
    if ($('.filter-az').length > 0) {
        $('.filter-az').mbie_overflow_helper();
    }
};
/* eslint-enable */
