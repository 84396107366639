export default () => {
    const el = document.querySelector('[data-masonry]');

    if (!el) return;

    const grid = el.querySelector('[data-masonry__grid]');
    const allItems = grid.querySelectorAll('[data-masonry__item]');
    const showMoreButton = el.querySelector('[data-masonry__show-more]');

    if (!grid || !allItems.length) return;

    const hasDisplayGrid = window.getComputedStyle(grid).getPropertyValue('display') === 'grid';

    const resizeGridItem = (item) => {
        const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
        const rowSpan = Math.ceil(
            (item.children[0].getBoundingClientRect().height + rowGap) / (rowHeight + rowGap),
        );

        item.style.setProperty('grid-row-end', `span ${rowSpan}`);
    };

    const showMoreClickHandler = () => {
        const showMoreInitialItems = showMoreButton.getAttribute('data-masonry__show-more');

        allItems.forEach((item, index) => {
            // Fix content jumping in Chrome
            el.style.setProperty('overflow-anchor', 'none');
            item.style.setProperty('display', 'block');

            if (hasDisplayGrid) {
                resizeGridItem(item);
            }

            if (index === parseInt(showMoreInitialItems, 10)) {
                item.querySelector('a').focus();
            }

            showMoreButton.style.setProperty('display', 'none');
            showMoreButton.removeEventListener('click', showMoreClickHandler);
        });
    };

    const showMoreItems = () => {
        const showMoreInitialItems = showMoreButton.getAttribute('data-masonry__show-more');

        el.setAttribute('data-state', 'show-more');

        allItems.forEach((item, index) => {
            if (index >= showMoreInitialItems) {
                item.style.setProperty('display', 'none');
            }
        });

        showMoreButton.addEventListener('click', showMoreClickHandler);
    };

    if (showMoreButton) {
        showMoreItems();
    }

    // Only create masonry layout if grid is fully supported
    if (!hasDisplayGrid) return;

    const resizeAllGridItems = () => {
        allItems.forEach((item) => {
            resizeGridItem(item);
        });
    };

    resizeAllGridItems();

    window.addEventListener('resize', resizeAllGridItems);
};
