import $ from 'jquery';

require('../plugins/jquery.fancybox.v2.1.5');

export default () => {
    // Generic modal
    $('.fancybox').fancybox({
        padding: 0,
        maxWidth: 1111,
    });

    // Additional close button
    $(document).on('click', '.mbie-modal .close-modal', () => {
        $('.fancybox-close').trigger('click');
        return false;
    });
};
