import $ from "jquery";

/* -------------------------------------
 FAUX RADIO/CHECKBOX INPUTS
 -------------------------------------
 */
$.fn.faux_radio_inputs = function () {

    return this.each(function () {
        var button = $(this),
            button_parent = button.parent(),
            container = button_parent.parent(),
            label = button_parent.children('label'),
            button_type = button.attr('type');

        button_parent.addClass('js-radio');
        label.addClass(button_type);

        // Keyboard access
        button.on('focus', function (event) {
            label.addClass('focus');
        });
        button.on('blur', function (event) {
            label.removeClass('focus');
        });

        // Radios
        if (button_type === 'radio') {
            // Disable button click to prevent multiple changes
            button.on('change', function () {
                if (button.prop('checked')) {
                    // Remove parent active class on all other non checked radios
                    container.find('input[type=radio]').not(':checked').parent().removeClass('selected').children('label').removeClass('active');
                    label.addClass('active');
                    button_parent.addClass('selected');
                }
            });
        }

        // Checkboxes
        if (button_type === 'checkbox') {
            // Change event
            button.on('change', function () {
                if (button.prop('checked') === false) {
                    button.prop('checked', false);
                    label.removeClass('active');
                    button_parent.removeClass('selected');
                } else {
                    button.prop('checked', true);
                    label.addClass('active');
                    button_parent.addClass('selected');
                }
            });
        }

        // Add active state on load
        if (button.filter(':checked').length > 0) {
            label.addClass('active');
            button_parent.addClass('selected');
        }

        // Label click event, trigger change
        label.on('click', function (event) {
            // Halt help icon clicks
            if ($(event.target).hasClass('icon-info-circled')) {
                return false;
            }
        });

    });

};
