import $ from 'jquery';
/* eslint-disable camelcase,func-names,no-plusplus */

/* -------------------------------------
 SECONDARY MENU COLLAPSIBLE
 -------------------------------------
 */
export default () => {
    const nav_secondary = $('.nav-secondary.collapsible');

    if (nav_secondary.length > 0) {
        // Add parent classes if required
        nav_secondary.find('li').each(function () {
            const parent = $(this);
            const first_child = parent.children();
            const children = parent.find('ul');
            if (children.length > 0) {
                // Check which should be the trigger, the <span>, or the <a>
                if (first_child.is('strong')) {
                    parent.addClass('parent').prepend('<span class="icon-plus"></span>');
                    parent.children('strong').addClass('js-link');
                } else {
                    parent.addClass('parent').prepend('<span class="js-link icon-plus"></span>');
                }
            }
        });
        // Events
        nav_secondary.find('.js-link').on('click', function () {
            const parent = $(this).parent();
            if (parent.length > 0) {
                if (parent.hasClass('active')) {
                    parent.removeClass('active');
                } else {
                    parent.addClass('active');
                }
            }
        });
        // Open current on load
        nav_secondary.find('.current').closest('.parent').addClass('active');
        nav_secondary.find('.active').closest('.child').parent('.parent').addClass('active');
    }
};
/* eslint-enable camelcase,func-names,no-plusplus */
